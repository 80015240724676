import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import RecipedetailBlock from './recipe_block_detail'


const ScienceBlock = (props) => {
    const handleClickOpen = () => {
        setOpen(true);
    };
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', maxWidth: '320px', maxHeight: '450px', paddingRight: '3vW', paddingBottom: '3vH', cursor: 'pointer' }} onClick={handleClickOpen}>
                <img alt='' style={{ height: '33%', borderTopLeftRadius: '25px', borderTopRightRadius: '25px' }} src={props.image} />
                <div style={{ height: '67%', borderBottomLeftRadius: '25px', borderBottomRightRadius: '25px', backgroundColor: '#c8cac0', maxHeight: '250px', overflow: 'hidden', paddingInline: '10px', boxShadow: '0px 0px 14px #0000000D', }}>
                    <p style={{ color: 'black', fontSize: '1.1rem' }} className="titles"><b>{props.title}</b></p>
                </div>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        width:'95vW',
                        maxHeight:'95vH',
                        margin:'0'
                    },
                }}
            >
                <DialogContent style={{ borderRadius: '25px',overflow: "hidden", padding:' 0 0 20px 0'}}>
                    <RecipedetailBlock title={props.title} text={props.text} details={props.details}></RecipedetailBlock>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ScienceBlock