import React from 'react'
import GoogleMapReact from 'google-map-react'
import Place from '@mui/icons-material/Place';
import CallIcon from '@mui/icons-material/Call';
import { Container } from '@mui/system';

const Map = ({ location, zoomLevel }) => (
  <Container className="map">
    <div className="google-map" style={{  height: '45VH' }}>
    <CallContact />
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyAOPjTBJteRfI_XgB0fyRnggivnEI2BtwU' }}
        defaultCenter={location}
        defaultZoom={zoomLevel}
        options={function (maps) { return { mapTypeId: "satellite" } }}
        yesIWantToUseGoogleMapApiInternals
      >
        <LocationPin
          lat={location.lat}
          lng={location.lng}
          text={location.address}
        />
      </GoogleMapReact>
    </div>
  </Container>
)

const LocationPin = ({ text }) => (
  <div className="pin"  >
    <Place className="pin-icon" style={{fontSize:'48px', textShadow:' 2px 2px black', stroke:'black', strokeWidth:'1'}} />
    <p className="pin-text"><b>{text}</b></p>
  </div>
)

const CallContact = () => (
  <div className="pin" style={{ display: 'flex', direction: 'row', justifyContent:'center', alignItems:'center' }} >
    <CallIcon className="pin-icon" style={{fontSize:'48px'}}/>
    <p style={{color:'#d67729', fontFamily: 'text', fontSize: '1.5rem'}}><b>066 58 22 799</b></p>
  </div>
)


  export default Map