import React from 'react'
import Header from '../common/header'
import BackImage from '../Images/BG-01.jpg'
import UtencileIcon1 from '../Images/uten_01.png'
import UtencileIcon2 from '../Images/uten_02.png'
import UtencileIcon3 from '../Images/uten_03.png'
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

function Recipes () {
        const navigate = useNavigate();
        return (
            <div style={{ minHeight: '100VH', backgroundImage: `url(${BackImage})` }}>
                <Header>
                </Header>
                <p style={{ color: '#c8cac0', fontSize: '4rem' }} className="titles">Recepti</p>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center',flexWrap: 'wrap' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', padding:'5vW', cursor: 'pointer'}} onClick={() => navigate('/recipes/healthy')}>
                        <img src={UtencileIcon1} alt='' style={{height:'13vW', objectFit: 'contain'}}/>
                        <Button style={{marginTop:'2vH',backgroundColor: '#c8cac0',borderRadius: '20px'}} ><p style={{ color: '#132E36',fontStyle:'italic',margin:'0' }} className="menu"><b>Odlična hrana</b></p></Button>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', padding:'5vW', cursor: 'pointer'}} onClick={() => navigate('/recipes/ununhealthy')}>
                        <img src={UtencileIcon2} alt='' style={{height:'13vW', objectFit: 'contain'}} />
                        <Button style={{marginTop:'2vH',backgroundColor: '#c8cac0',borderRadius: '20px'}} ><p style={{ color: '#132E36',fontStyle:'italic',margin:'0' }} className="menu"><b>Dobra hrana</b></p></Button>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', padding:'5vW', cursor: 'pointer'}} onClick={() => navigate('/recipes/unhealthy')}>
                        <img src={UtencileIcon3} alt='' style={{height:'13vW', objectFit: 'contain'}}/>
                        <Button style={{marginTop:'2vH',backgroundColor: '#c8cac0',borderRadius: '20px'}} ><p style={{ color: '#132E36',fontStyle:'italic',margin:'0' }} className="menu" ><b>Hrana</b></p></Button>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        )
    }

export default Recipes