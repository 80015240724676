import React from 'react'
import Header from '../common/header'
import BackImage from '../Images/BG-01.jpg'
import RecipeBlock from './recipe_block'
import HealthyRecipes from './healthy.json'
import UnHealthyRecipes from './unhealthy.json'
import UnUnHealthyRecipes from './ununhealthy.json'
import { Container } from '@mui/material'




const RecipesDetailed = (props) => {
    return (
        <div style={{ minHeight: '100VH', backgroundImage: `url(${BackImage})` }}>
            <Header>
            </Header>
            <p style={{ color: '#c8cac0', fontSize: '4rem', paddingBottom:'10vH', paddingTop:'5vH' }} className="titles">{props.title}</p>
            <Container spacing={2} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
                {props.title === "Odlična hrana" ? HealthyRecipes.recipes.map(({ id, title, image, text_short, text_long }) => { return <RecipeBlock text={text_short} title={title} image={image} key={id} details={text_long} ></RecipeBlock> }) : <></>}
                {props.title === "Dobra hrana" ? UnUnHealthyRecipes.recipes.map(({ id, title, image, text_short, text_long }) => { return <RecipeBlock text={text_short} title={title} image={image} key={id} details={text_long} ></RecipeBlock> }) : <></>}
                {props.title === "Hrana" ? UnHealthyRecipes.recipes.map(({ id, title, image, text_short, text_long }) => { return <RecipeBlock text={text_short} title={title} image={image} key={id} details={text_long} ></RecipeBlock> }) : <></>}
            </Container>
        </div>

    )
}

export default RecipesDetailed  