import * as React from 'react';
import FacebookIcon from '../Images/socials_01.png'
import InstagramIcon from '../Images/socials_02.png'
import LinkedInIcon from '../Images/socials_03.png'
import TikTokIcon from '../Images/socials_04.png'
import PhoneCallIcon from '../Images/socials_05.png'
import { Link } from 'react-router-dom';



function Links() {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
            <div>
            <Link to="https://www.facebook.com/D.Aragonski/"> <img src={FacebookIcon} alt='' style={{ width: '50px', borderRadius: '100%', padding: '8px'}} /></Link>
            </div>
            <div>
            <Link to="https://www.instagram.com/dusan.aragonski/">   <img src={InstagramIcon} alt='' style={{ width: '50px', borderRadius: '100%', padding: '8px'}} /></Link>
            </div>
            <div>
            <Link to="https://www.linkedin.com/in/dušan-aragonski-ba28b4208/">   <img src={LinkedInIcon} alt='' style={{ width: '50px', borderRadius: '100%', padding: '8px'}} /></Link>
            </div>
            <div>
            <Link to="https://www.tiktok.com/@dusanaragonski">   <img src={TikTokIcon} alt='' style={{ width: '50px', borderRadius: '100%', padding: '8px'}} /></Link>
            </div>
            <div>
            <Link to="tel:0665822799"><img src={PhoneCallIcon} alt='' style={{ width: '50px', borderRadius: '100%', padding: '8px'}} /></Link>
            </div>
        </div>
    )
}

export default Links