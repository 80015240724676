import * as React from 'react';
import Button from '@mui/material/Button';
import { Container } from '@mui/system';
import PersonIcon from '../Images/person.png'
import LetterIcon from '../Images/letter.png'
import UtencileIcon from '../Images/utenciles.png'
import LightbulbIcon from '../Images/lightbulb.png'
import { useNavigate } from 'react-router-dom';



function Menu(props) {
    const navigate = useNavigate();

    return (
        <Container style={{ backgroundColor: '#1b3942', height: '70vH', justifyContent:'space-around', padding:'10%', alignItems:'center', display:'flex' ,flexWrap: 'wrap'}}>
                <div style={{ minWidth:'250px', maxWidth: '320px',flexGrow:'1', padding:'3vW' }}>
                    <img src={PersonIcon} alt='' style={{width: '50px', marginTop: '-10px', marginLeft:'-15px', backgroundColor: '#132E36', borderRadius: '100%', padding: '8px', zIndex: '2', position: 'absolute' }} />
                    <Button style={{ backgroundColor: '#c8cac0', width: '100%', height: '45px' }} onClick={() => navigate('/biography')}>
                        <p style={{ color: '#132E36', fontStyle:'italic' }} className="menu"><b>Biografija</b></p>
                    </Button>
                </div>
                <div style={{ minWidth:'250px', maxWidth: '320px',flexGrow:'1',padding:'3vW' }}>
                <img src={UtencileIcon}  alt='' style={{width: '50px', marginTop: '-10px', marginLeft:'-15px', backgroundColor: '#132E36', borderRadius: '100%', padding: '8px', zIndex: '2', position: 'absolute' }} />
                    <Button style={{ backgroundColor: '#c8cac0', width: '100%', height: '45px'   }} onClick={() => navigate('/recipes')}>
                        <p style={{ color: '#132E36', fontStyle:'italic' }} className="menu" ><b>Recepti</b></p>
                    </Button>
                </div>
                <div style={{ minWidth:'250px', maxWidth: '320px',flexGrow:'1', padding:'3vW' }}>
                <img src={LightbulbIcon} alt='' style={{ width: '50px', marginTop: '-10px', marginLeft:'-15px', backgroundColor: '#132E36', borderRadius: '100%', padding: '8px', zIndex: '2', position: 'absolute' }} />
                    <Button style={{ backgroundColor: '#c8cac0', width: '100%', height: '45px'  }} onClick={() => navigate('/science')}>
                        <p style={{ color: '#132E36', fontStyle:'italic' }} className="menu" ><b>Nauka Ishrane</b></p>
                    </Button>
                </div>
                <div style={{ minWidth:'250px', maxWidth: '320px', flexGrow:'1', padding:'3vW'}}>
                <img src={LetterIcon} alt='' style={{ width: '50px', marginTop: '-10px', marginLeft:'-15px', backgroundColor: '#132E36', borderRadius: '100%', padding: '8px', zIndex: '2', position: 'absolute' }} />
                    <Button style={{ backgroundColor: '#c8cac0', width: '100%', height: '45px' }} onClick={() => navigate('/contact')}>
                        <p style={{ color: '#132E36', fontStyle:'italic' }} className="menu" ><b>Kontakt</b></p>
                    </Button>
                </div>
        </Container>
    )
}

export default Menu