import React, { Component } from 'react'
import Header from '../common/header'
import ScienceBlock from './science_block'
import Blogs from './blog_texts.json'
import { Container } from '@mui/material'


class Science extends Component {
    render() {
        return (
            <div style={{ minHeight: '100VH' }}>
                <Header>
                </Header>
                <p style={{ color: '#c8cac0', fontSize: '4rem' }} className="titles">Nauka Ishrane</p>
                <div style={{display:'flex'}}>
                    <Container spacing={2} style={{ display: 'flex', flexDirection: 'row',  alignItems: 'center',  flexWrap: 'wrap', justifyContent:'center' }}>
                        {Blogs.articles.map(({ post_no, title, image, text_short}) => { return <ScienceBlock text={text_short} title={title} image={image} key={post_no} identity={post_no}></ScienceBlock> })}
                    </Container>
                </div>
            </div>
        )
    }
}

export default Science