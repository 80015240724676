import { Container } from '@mui/material'
import React, { Component } from 'react'
import Header from '../common/header'
import MainPicture from '../Images/Main01.png'
import Office from './office'
import Links from '../common/links'

class Home extends Component {
    render() {
        return (
            <div>
                <div style={{ minHeight: '100%', scrollSnapAlign: 'start' }} >
                    <Header>
                    </Header>
                    <Container style={{ display: 'flex', height: '90VH', justifyContent:'center' }} >
                        <div >
                            <img src={MainPicture} style={{ maxWidth: '90vW', maxHeight: '75vH', objectFit: 'contain', position:'absolute',bottom:'0', left:'0', marginLeft:'20px'}} alt='' />
                        </div>
                        <p style={{ color: '#c8cac0', fontSize: '2.5rem', zIndex:2, marginTop:'20vW', right:'0', position:'absolute', marginRight:'3VW'}} className="titles">Svaki obrok je nova prilika za promenu navika</p>
                        <div style={{ display: 'flex', flexWrap:'wrap', position: 'absolute', bottom:'0', right:'0', marginBottom:'20px' ,marginRight:'20px'}}><Links></Links></div>
                    </Container>
                </div>
                <Office></Office>
            </div>
        )
    }
}

export default Home