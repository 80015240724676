import './App.css';
import Home from './home/home'
import Contact from './contact/contact';
import Recipes from './recipes/recipes';
import Science from './food_science/science';
import Biography from './biography/biography';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RecipesDetailed from './recipes/recipes_detailed';
import ScienceDetail from './food_science/science_detail';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route path="/"  element={<Home></Home>} />
          <Route path="/biography"  element={<Biography> </Biography>} />
          <Route path="/contact"  element={<Contact></Contact>} />
          <Route path="/science"  element={<Science></Science>} />
          <Route path="/recipes"  element={<Recipes></Recipes>} />
          <Route path="/recipes/healthy"  element={<RecipesDetailed title="Odlična hrana"></RecipesDetailed>} />
          <Route path="/recipes/ununhealthy"  element={<RecipesDetailed title="Dobra hrana"></RecipesDetailed>} />
          <Route path="/recipes/unhealthy"  element={<RecipesDetailed title="Hrana"></RecipesDetailed>} />
          <Route path="/science/blog_post/:id"  element={<ScienceDetail></ScienceDetail>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
