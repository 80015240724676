import { Container } from '@mui/system'
import React, { Component } from 'react'
import Header from '../common/header'

class Biography extends Component {
    render() {
        return (
            <div style={{ minHeight: '100VH' }}>
                <Header>
                </Header>
                <div >
                    <p style={{ color: '#c8cac0', fontSize: '4rem' }} className="titles">Biografija</p>
                    <div style={{ display: 'flex', flexDirection: 'row', minWidth: '100%' }}>
                        <Container style={{zIndex:2, right:'0', paddingRight:'5vW', paddingLeft:'5vW'}}>
                            <p style={{ color: '#c8cac0', textShadow: '1px 1px 1px #214751'}}>Pozdrav, ja sam Du&scaron;an Aragonski,</p>
                            <p style={{ color: '#c8cac0', textShadow: '1px 1px 1px #214751'}}>Edukator, diplomirani biohemičar i nutricionista. Svrha mog rada je da pomognem ljudima da postignu optimalno zdravlje i ostvare dobre rezultate putem ishrane i promene životnih navika.</p>
                            <p style={{ color: '#c8cac0', textShadow: '1px 1px 1px #214751'}}>Uz moju pomoć, desetine pacijenata svaki mesec uspeva da smanji težinu za 2 do 5 kilograma, bez većih napora i odricanja. To postižemo putem individualnih sastanaka, normalizacije ishrane i ubrzavanja metabolizma. Dodatno, rezultatima doprinosi multidisciplinarni pristup nutricionizmu koji je proizvod saradnje sa brojnim profesionalcima u svojim oblastima, poput lekara specijalista, brojnih trenera, do uzgajivača zdravih namirnica.</p>
                            <p style={{ color: '#c8cac0', textShadow: '1px 1px 1px #214751'}}>Najznačajnija karakteristika mog pristupa ishrani je odsustvo "Tvrdih restrikcija", odnosno ne smatram da postoji hrana koja ne sme da se jede, kao ni savr&scaron;ena hrana. Umesto toga okrenut sam ka "Mekim restrikcijama", poput: "Probaj ovo če&scaron;će" i "Nemoj ovo ba&scaron; svaki dan".</p>
                            <p style={{ color: '#c8cac0', textShadow: '1px 1px 1px #214751'}}>Bio sam siguran da postoji lak&scaron;i način za kontrolu težine kada sam video ženu koja meri hranu na vagi, studentkinje koje jedu laneno seme za večeru i čuo "nutricionistu/trenera" koji se dere na čoveka. Način koji ja zastupam nije najbrži, nije najlak&scaron;i i nije najzanimljiviji, ali jeste siguran, zdrav i do sada je radio na preko 93% ljudi sa kojima sam sarađivao.</p>
                            <p style={{ color: '#c8cac0', textShadow: '1px 1px 1px #214751'}}>Kao edukator, u saradnji sa nekoliko organizacija, održao sam brojna predavanja na temu ishrane kojima je prisustvovalo preko 2.000 ljudi. &Scaron;irenje znanja i ukazivanje na jednostavne načine za unapređenje života predstavlja krunu moga rada i ne&scaron;to na &scaron;ta sam izuzetno ponosan.</p>
                            <p style={{ color: '#c8cac0', textShadow: '1px 1px 1px #214751'}}>Aragnoski pristup podrazumeva postepeno uvođenje promena, kako bi čovek i njegov metabolizam imali vremena da se naviknu i usvoje promenu. Biohemijski procesi mogu da budu veoma komplikovani, ali pravila koja dajem su jednostavna za po&scaron;tovanje i primenu. Tvoje prisustvo ovde znači da si već napravio prvi korak! Kontaktiraj me kako bismo zajedno napravili sledeći.</p>
                        </Container>
                    </div>
                </div>
            </div>
        )
    }
}

export default Biography