import React from 'react'


const BlogPost1 = () => {
    return (
        <div style={{width:'100VW', overflowWrap: 'anywhere'}}>
            <p>&Scaron;ta je i da li ishrana po krvnim grupama ima smisla?</p>
<p>Ishrana po krvnim grupama popularizovana je knjigom Jedite pravilno za va&scaron;u krvnu grupu &ndash; Individualno re&scaron;enje ishrane za krvne grupe, napisane od strane naturopate Peter J. D'Adamo i objavljene 1996. godine. Ova knjiga dobila je nagradu Njujork Tajmsa za najprodavaniju knjigu o dijetama i prodata je u vi&scaron;e od milion primeraka. Ishrana po krvnim grupama popularna je i danas, 25 godina kasnije, i veliki broj ljudi me je pitao za mi&scaron;ljenje o njoj, zbog čega sam i odlučio da napi&scaron;em ovaj članak.</p>
<p><a href="https://www.amazon.com/Right-Your-Type-Revised-Updated-ebook/dp/B00256Z2HI">https://www.amazon.com/Right-Your-Type-Revised-Updated-ebook/dp/B00256Z2HI</a></p>
<p>Ideja ishrane po krvnim grupama zasniva se na pretpostavci da ljudi sa različitim krvnim grupama različito vare određene proteine u hrani. Zbog ovoga bi konzumiranje hrane koja nije u skladu sa njihovom krvnom grupom kod ljudi moglo da izazove zdravstvene probleme, a konzumacija pogodne hrane da vodi dužem i zdravijem životu.</p>
<p>Prema hipotezi ishrane zasnovane na krvnim grupama, svi ljudi sa zajedničkom krvnom grupom imaju i zajedničko evolutivno poreklo, odnosno zajedničke petke koji su konzumirali sličnu hranu. Iz ovoga sledi da će ljudima sa istom krvnom grupom odgovarati isti ili sličan tip ishrane kako su evolutivno pripremljeni za varenje te vrste hrane.</p>
<p>Krvne grupe i ono &scaron;to bi trebale da jedu su:</p>
<ul>
<li>Grupa 0 (Lovci) &ndash; Smatraju se najstarijom grupom ljudi i trebalo bi da se hrane ishranom koja opona&scaron;a najstariju ishranu kakvu su imala dru&scaron;tva lovaca i sakupljača u praistoriji. Dakle, ishrana ove grupe trebala bi da bude bogata životinjskim proteinima iz mesa i ribe, uz dodatak bobičastog voća i povrća, ali bez mlečnih proizvoda i mleka, pasuljastog povrća i žitarica.</li>
<li>Grupa A (Uzgajivači) &ndash; Pretpostavlja se da je ova krvna grupa nastala kada je počeo aktivan uzgoj i agrikultura, pa se ovim ljudima predlaže ishrana zasnovana na biljkama uključujući voće, povrće i cele žitarice, ali se savetuje izbegavanje crvenog mesa.</li>
<li>Grupa B (Nomadi) &ndash; Spekuli&scaron;e se da je ova krvna grupa evoluirala među nomadskim plemenima, pa se predlaže ishrana bogata mlečnim proizvodima i mlekom, a uključuje i voće, žitarice, zeleno povrće, jaja i meso, ali ne svinjsko ili pileće. Dodatno, savetuje se izbegavanje kukuruza, p&scaron;enice, kikirikija, sočiva, paradajza i jo&scaron; nekoliko biljaka.</li>
<li>Grupa AB (Izme&scaron;ani) &ndash; Navodno su nastali me&scaron;anjem grupa A i B, i imaju prednosti i probleme obe grupe. Najbolji izvor proteina za ovu grupu je morska hrana, a takođe im se predlaže konzumacija jaja, tofua, povrća, jogurta i kefira. Savetuje im se izbegavanje kofeina i alkohola, kao i obrađenog mesa.</li>
</ul>
<p>Da li ishrana zasnovana na krvnim grupama ima smisla?</p>
<p>Ukoliko želite prost odgovor &ndash; apsolutno i nedvosmisleno NE.</p>
<p>ABO krvne grupe otkrivene su pre vi&scaron;e od sto godina i od tada intenzivno proučavane. Ustanovljene su razlike u sklonostima ka bolestima za različite krvne grupe, ali odgovor na različitu ishranu nikada nije naučno potvrđen.</p>
<p>Dok ideja različite ishrane zasnovane na različitom evolutivnom poreklu apsolutno ima smisla, ljudska genetika je mnogo složenija od proste podele na četiri grupe. Dodatno, pretpostavka da ista krvna grupa označava isto evolutivno poreklo nije potkrepljena nikakvim dokazima. Na primer, neki naučnici smatraju da se <a href="https://pubmed.ncbi.nlm.nih.gov/18629539/">https://pubmed.ncbi.nlm.nih.gov/18629539/</a> 0 krvna grupa razvila nezavisno barem četiri puta u ljudskoj istoriji. Ovime ne samo da se pobija ideja o zajedničkom pretku, već i teorija da je 0 grupa nastala pre drugih krvnih grupa, te da treba opona&scaron;ati ishranu kakvu su imali prvi ljudi.</p>
<p>Dijeta zasnovana na krvnim grupama u&scaron;la je na listu &sbquo;&sbquo; Top 5 Poznatih Dijeta Koje Treba Izbegavati u 2019.&rsquo;&rsquo; britanske asocijacije dijetičara, i nalazi se na počasnom prvom mestu. Ova asocijacija kaže da je ishrana zasnovana na krvnim grupama u potpunosti zasnovana na pseudo-nauci i upozoravaju na opasnost izbacivanja čitavih grupa namirnica iz ishrane, zbog moguće pojave malnutricije. Druge prakse koje se nalaze na ovoj listi su: Konzumiranje sopstvene mokraće, Detoks čajevi i Kafe za mr&scaron;avljenje, Alkalna voda i Vrećice za mr&scaron;avljenje.</p>
<p><a href="https://www.bda.uk.com/resource/bda-releases-top-5-celeb-diets-to-avoid-in-2019.html">https://www.bda.uk.com/resource/bda-releases-top-5-celeb-diets-to-avoid-in-2019.html</a></p>
<p>Istraživanja</p>
<p>Jedna studija istraživala je uticaj ishrana po krvnim grupama na zdravlje ljudi. U njoj je učestvovalo 1455 ljudi različitog pola, starosti i etničke pripadnosti. Zaključak studije je da dijeta ima pozitivne efekte na neke od zdravstvenih parametara, ali da ne postoji povezanost krvne grupe sa načinom optimalne ishrane. Po&scaron;to uglavnom uključuje zdrave i zabranjuje unos prerađenih namirnica, svaki od četiri oblika ove dijete zdraviji od onoga &scaron;to većina ljudi svakodnevno jede. Studija nije do&scaron;la do otkrića koja bi podržala hipotezu o krvnim grupama. <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3893150/">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3893150/</a></p>
<p>Manje istraživanje, objavljeno 2021. godine, imalo je drugačiji pristup. 68 gojaznih pacijenata dobili su identičnu dijetu zasnovanu na biljnoj ishrani sa malo masti. Učesnici istraživanja pratili su ovaj režim 16 nedelja i postigli slične rezultate, oko 7 izgubljenih kilograma, nezavisno od krvne grupe. Naučnici zaključuju da krvna grupa nije povezana sa efektima biljne ishrane na gubitak telesne težine, količine masti na telu, nivoa masti u krvi ili glikemijskoj kontroli. <a href="https://www.sciencedirect.com/science/article/pii/S2212267220311977">https://www.sciencedirect.com/science/article/pii/S2212267220311977</a></p>
<p>&Scaron;ta ima smisla?</p>
<p>Ishrana koja je zasnovana na poreklu može imati utemeljenje. Primer za ovo je tolerancija na laktozu koju ima veliki broj ljudi u predelima u kojima postoji kultura konzumacije mleka i mlečnih proizvoda. Sa druge strane stanovnici afričkih i azijskih zemalja, u kojima konzumacija mleka nakon ranog detinjstva nije praksa, imaju veoma čestu intoleranciju na laktozu. Ovo ukazuje na različitu sposobnost tela za proizvodnju enzima za varenje zasnovanu na geografskom poreklu i onome &scaron;to su na&scaron;i preci konzumirali. Sledi da će ljudi na ovim prostorima imati enzime za varenje hrane koja se ovde u pro&scaron;losti jela, poput hleba, žitarica, mesa, rotkvica, luka, lokalnih pečuraka, kru&scaron;aka, jabuka, borovnica, &scaron;ljiva, smokvi, tre&scaron;nji, breskvi, jaja, mleka i mlečnih proizvoda.</p>
<p>Iako ishrana zasnovana na krvnoj grupi nema smisla, različite namirnice zaista smetaju različitim ljudima. Ovo može imati veze sa geografskim ili etničkim poreklom, ali je če&scaron;će individualno i povezano sa detinjstvom i odrastanjem. Smetnje mogu biti od veoma burnih, poput alergijskih reakcija, do veoma blagih, koje je nemoguće primetiti. Ukoliko mislite da Vam određena hrana ne prija ili da imate intoleranciju većina biohemijskih laboratorija nudi ispitivanja intolerancije na hranu. Ova ispitivanja su ne&scaron;to skuplja, ali Vam daju jasne i pouzdane podatke o namirnicama koje treba izbegavati.</p>
<p>Umerena i raznovrsna ishrana je po ogromnom broju istraživanja najbolji i najzdraviji način ishrane. Dodatno, ishrana koja se bazira na biljkama pokazala je najbolje rezultate na gotovo sve zdravstvene parametre, a vi&scaron;e o ovakvoj ishrani i njenom dejstvu možete pročitati u mom članku o Mediteranskoj ishrani.</p>
<p>Ukoliko razmi&scaron;ljate da napravite promene u ishrani sa ciljem unapređenja zdravlja ili postizanja željene težine, nemojte se ustručavati da me kontaktirate, bilo putem sajta, telefona ili dru&scaron;tvenih mreža.</p>
<p>Druge interesantne članke možete naći na mom BLOG-u.</p>
        </div>
    )}

export default BlogPost1