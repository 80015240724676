import { Container } from '@mui/system'
import React from 'react';
import Header from '../common/header'
import Blogs from './blog_texts.json'  



const ScienceDetail = () =>  {
    const url = window.location.pathname
    window.scrollTo(0, 0);
    const BlogPost = url.split("/").pop()
    const BlogTextComponent =   require(`./blog_html/blog_${BlogPost}.js`).default
    const image =  "/" + Blogs.articles[BlogPost].large_image
            return (
                <div style={{ minHeight: '100VH', }}>
                    <div style={{backgroundImage:`url(${image})`, backgroundRepeat:'no-repeat', backgroundSize:'cover' }}>
                    <Header>
                    </Header>
                    <p style={{color: '#c8cac0', fontSize: '3.2rem', textShadow: '3px 3px 3px #214751',marginBottom:'0'}} className="titles" >{Blogs.articles[BlogPost].title}</p>
                    </div>
                    <div  style={{minWidth:'100%', backgroundColor: '#c8cac0'}}>
                    <Container style={{display: 'flex', flexDirection: 'row'}}>
                        <BlogTextComponent ></BlogTextComponent>
                    </Container>
                    </div>
                </div>
            )
}

export default ScienceDetail